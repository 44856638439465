import { Vue, Component, Prop } from 'vue-property-decorator';
import UIkit from 'uikit';
import { ScreenText } from '@/lang/ScreenText';
import DatepickerComponent from '@/ui-components/datepicker/DatepickerComponent.vue';
import { getProgramByAccount, getSiteByProgramId } from '@/services/create-user/createUserService';
import APP_CONST from '@/constants/AppConst';
import { IProgram } from '@/Model/programModel';
import APP_UTILITIES from '@/utilities/commonFunctions';
import ProgressButton from '@/commoncomponents/progressButton/progressButton.vue';
import { saveBulkPrivileges } from '@/services/userService/users-api';
import MultiSelectDropdown from '@/ui-components/multiSelectDropdown/MultiSelectDropdown.vue';
import manageList from '@/store/modules/manageList';
import { getModule } from 'vuex-module-decorators';
import { GlobalModule } from '@/store/global/globalModule';
import store from '@/store';
import DropdownList from '@/ui-components/dropdownListBx/DropdownList.vue';
import { undoBulkPrivileges } from '@/services/userService/users-api';
import { ToastType } from '@/Model/toastType';
@Component({
  components: {
    'progress-button': ProgressButton,
    'datepicker': DatepickerComponent,
    'multi-select-dropdown': MultiSelectDropdown,
    'dropdown': DropdownList,

  },
})
export default class AddPrivilegesPopUp extends Vue {
  private objScreenText: ScreenText = new ScreenText();
  public allPrograms: any = [];
  public unfilteredProgramList: any = [];
  public currentRoleId: number = 0;
  public accountId: any;
  public selectedProgram: any = {};
  public scheduleDate: string = APP_CONST.BLANK;
  public allSites: any[] = [];
  public failedPrivilegeUsersName: any = {};
  public selectedPrograms: Array<number> = [];
  public selectedSites: Array<number> = [];
  getGlobalState = getModule(GlobalModule, store);
  public undoPayload: any = {};
  public statusChangeTimer: number = 0;

  /* istanbul ignore next */
  get multiProgramDropdown() {
    return manageList.getmultiProgramDropdown;
  }

  /* istanbul ignore next */
  get singleProgramDropdown() {
    return manageList.getsingleProgramDropdown;
  }

  /* istanbul ignore next */
  get multiSiteDropdown() {
    return manageList.getmultiSiteDropdown;
  }

  @Prop({ default: '' })
  component!: string;

  @Prop()
  add_users!: [];

  @Prop()
  role!: string;

  @Prop()
  bulkPrivilegeSearch!: { search: string; searchField: string };

  mounted() {
    if (Vue.prototype.$eventHub) {
      Vue.prototype.$eventHub.$on('undoChanges', this.undoChanges);
    }

    const userRoles: any = APP_UTILITIES.getCookie('highest_role');
    const userData = userRoles && JSON.parse(userRoles);
    this.currentRoleId = userData;
    if (this.currentRoleId >= 4) {
      const role: any = APP_UTILITIES.getCookie('user_role');
      const userInfo = JSON.parse(role);
      this.accountId = userInfo[0].accountId;
    }
    else {
      this.accountId = APP_UTILITIES.getCookie('accountId');
      this.accountId = JSON.parse(this.accountId);
    }
    this.getPrograms(this.accountId);
    const ele: any = document.getElementById('add-privileges-popup');
  }

  close() {
    if (UIkit.modal('#add-privileges-popup')) {
      UIkit.modal('#add-privileges-popup').hide();
    }
    this.allSites = [];
    this.selectedSites = [];
    this.selectedPrograms = [];
    this.selectedProgram = '';
    this.scheduleDate = '';
    this.allPrograms.forEach((program: { isChecked: boolean }) => {
      program.isChecked = false; 
    });
    const checkDropdownArray: { id: any; value: any; checked: any }[] = [];
    this.unfilteredProgramList.forEach((data: { id: any; name: any; isChecked: any }) => {
      checkDropdownArray.push({ id: data.id, value: data.name, checked: false });
    });
    manageList.mutatemultiProgramDropdown({ ...this.multiProgramDropdown, dropdownList: checkDropdownArray });
    this.getGlobalState.mutateSelectedRecordsMultiSelect([]);
    this.getGlobalState.mutateFewDataChecked([]);
    manageList.mutatesingleProgramDropdown({ ...this.singleProgramDropdown, value: 'Select' });
    manageList.mutatemultiSiteDropdown({ ...this.multiSiteDropdown, dropdownList: [] });

  }


  public getScreenText(key: string): string {
    return this.objScreenText.getScreenText(key);
  }

  getPrograms(accountId: number) {
    getProgramByAccount(accountId).then((res: any) => {
      if (res.status === APP_CONST.RESPONSE_200) {
        const data = res.data;
        this.allPrograms = data.filter((program: any) => {
          return program.status === 1;
        }) as IProgram[];
        this.allPrograms.forEach((program: any) => {
          program[APP_CONST.IS_CHECKED] = false;
        });
        this.unfilteredProgramList = this.allPrograms;
        const checkDropdownArray: { id: any; value: any; checked: any }[] = [];
        const dropdownArray: { id: any; value: any }[] = [];
        this.unfilteredProgramList.forEach((data: { id: any; name: any; isChecked: any }) => {
          checkDropdownArray.push({ id: data.id, value: data.name, checked: data.isChecked });
          dropdownArray.push({ id: data.id, value: data.name });
        });
        manageList.mutatemultiProgramDropdown({ ...this.multiProgramDropdown, dropdownList: checkDropdownArray });
        manageList.mutatesingleProgramDropdown({ ...this.singleProgramDropdown, dropdownList: dropdownArray });

      }
      else {
        this.allPrograms = [];
      }
    });
  }

  getSites(programId: number) {
    getSiteByProgramId(programId).then((res: any) => {
      if (res.status === APP_CONST.RESPONSE_200) {
        const data = res.data;
        this.allSites = data;
        this.allSites = data.filter((site: any) => {
          return site.status === 1;
        });
        this.allSites.forEach((site: any) => {
          site[APP_CONST.IS_CHECKED] = false;
        });
        const checkDropdownArray: { id: any; value: any; checked: any }[] = [];
        this.allSites.forEach((data: { siteId: any; siteName: any; isChecked: any }) => {
          checkDropdownArray.push({ id: data.siteId, value: data.siteName, checked: data.isChecked });
        });
        manageList.mutatemultiSiteDropdown({ ...this.multiSiteDropdown, dropdownList: checkDropdownArray });
      }
      else {
        this.allSites = [];
      }
    });
  }

  savePrivileges() {
    const rolesList = JSON.parse(JSON.stringify(APP_CONST.SYSTEM_ROLE_LIST));
    const index = rolesList.findIndex((ele: any) => ele.name.toLowerCase() == this.role);
    const payload: { accountId: number; programIds: number[]; siteIds: number[]; scheduleDate: string; roleId: number; SearchField: string; Search: string; userIds: number[] } = {
      'accountId': this.accountId,
      'programIds': [],
      'siteIds': [],
      'scheduleDate': this.scheduleDate,
      'roleId': rolesList[index] && rolesList[index].id,
      'SearchField': this.bulkPrivilegeSearch && this.bulkPrivilegeSearch.searchField,
      'Search': this.bulkPrivilegeSearch && decodeURIComponent(this.bulkPrivilegeSearch.search),
      'userIds': this.add_users
    };
    if (this.role == 'program admin') {
      this.selectedPrograms.forEach(programId => {
        payload.programIds.push(programId);

      });
      payload.siteIds = [0];
    }
    else {
      payload.programIds.push(this.selectedProgram.id);
      this.selectedSites.forEach(site => {
        payload.siteIds.push(site);
      });
    }
    this.failedPrivilegeUsersName = [];

    saveBulkPrivileges(payload).then(response => {
      if (response.status == APP_CONST.RESPONSE_200) {

        this.close();
        response && response.data && response.data.fail && response.data.fail.forEach((failUsers: any) => {
          const fullName = failUsers.firstName + ' ' + failUsers.lastName;
          this.failedPrivilegeUsersName.push(fullName);
        });

        /* istanbul ignore else */
        if (this.failedPrivilegeUsersName.length) {
          this.$emit('failedPrivileges', this.failedPrivilegeUsersName);
        }
        else if (response.data) {
          this.undoPayload = response.data;
          Vue.prototype.$eventHub.$emit('showAttendanceUndoStripIndicater', {
            component: 'bulkAddPrivileges',
            stripShow: true,
            attendanceStripShow: true,
            stripText: APP_CONST.SUCCESS_TEXT_BANNER
          });

          this.statusChangeTimer = window.setTimeout(() => {
            Vue.prototype.$eventHub.$emit('showAttendanceUndoStripIndicater', {
              component: 'bulkAddPrivileges',
              stripShow: false,
              attendanceStripShow: false,
              stripText: ''
            });

          }, APP_CONST.AUTO_SAVE_INTERVAL);
        }
        this.reloadTable();
      }
    });
  }

  reloadTable() {
    this.$emit('reload_table');
  }

  multiProgramSelection(multiProgramDropdown: any) {
    this.selectedPrograms = [];
    multiProgramDropdown.dropdownList.forEach((program: any) => {
      /* istanbul ignore else */
      if (program.checked) {
        this.allPrograms.forEach((prog: any) => {
          /* istanbul ignore else */
          if (prog.id == program.id) {
            prog.isChecked = program.checked;
          }
        });
        this.selectedPrograms.push(program.id);
      }
    });
  }

  singleProgramSelection(selection: { id: number; value: string }) {
    /* istanbul ignore else */
    if (selection) {
      this.selectedProgram = selection;
      this.getSites(selection.id);
      this.getGlobalState.mutateSelectedRecordsMultiSelect([]);
      this.getGlobalState.mutateFewDataChecked([]);
    }
  }

  multiSiteSelection(multiSiteDropdown: any) {
    this.selectedSites = [];
    multiSiteDropdown.dropdownList.forEach((site: any) => {
      /* istanbul ignore else */
      if (site.checked) {
        this.allSites.forEach((sit: any) => {
          /* istanbul ignore else */
          if (sit.siteId == site.id) {
            sit.isChecked = site.checked;
          }
        });
        this.selectedSites.push(site.id);
      }
    });
  }

  undoChanges() {
    if (this.undoPayload && this.undoPayload.accountId) {
      undoBulkPrivileges(this.undoPayload).then(() => {
        this.reloadTable();
      });
    }
    APP_UTILITIES.showToastMessage(APP_CONST.ACTION_UNDONE, ToastType.Success);
    this.hideOrangeBar();

  }



  hideOrangeBar() {
    if (this.statusChangeTimer > 0) {
      clearTimeout(this.statusChangeTimer);
      this.statusChangeTimer = 0;
      Vue.prototype.$eventHub.$emit('showAttendanceUndoStripIndicater', {
        component: 'bulkAddPrivileges',
        stripShow: false,
        attendanceStripShow: false,
      });
    }
  }
}